import React, { useState, useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { IconQuotes, IconStar } from '../components/Icons'
import useInfinteScroll from '../hooks/useInfiniteScroll'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const TestimonialCard = ({ name, date, images, text }) => {
  const [expand, setExpand] = useState(false)
  const close = useCallback(() => setExpand(false), [setExpand])
  const show = useCallback(() => setExpand(true), [setExpand])

  // set state to show selected image
  const [selectedImage, setSelectedImage] = useState(0)

  return (
    <>
      <div
        onClick={show}
        className={
          (images && images.length > 0
            ? 'border border-gray-300 rounded-lg aspect-[10/12]'
            : ' rounded-full aspect-square ') +
          ' group relative p-4 duration-200 hover:border-blue-200 hover:shadow-md flex flex-col justify-center items-center cursor-pointer '
        }
      >
        {images && images.length > 0 && (
          <div className="aspect-square w-full flex justify-center">
            <GatsbyImage
              image={getImage(images[0])}
              alt={images[0].url}
              className={'aspect-square object-cover w-full'}
            />
          </div>
        )}
        {!(images && images.length > 0) && (
          <div className="absolute w-[95%] border-4 border-blue-100 rounded-full aspect-square top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            <div className="rotate-180 text-blue-100 w-3/12 p-2 aspect-[75/60] flex justify-center items-center bg-white rounded-full">
              <IconQuotes className="w-full h-full" />
            </div>
            <div className="absolute bottom-0 right-0 text-blue-100 w-3/12 p-2 aspect-[75/60] flex justify-center items-center bg-white rounded-full">
              <IconQuotes className="w-full h-full" />
            </div>
          </div>
        )}
        <p
          className={
            'mt-2 ' + (images && images.length > 0 ? '' : 'text-center w-10/12 -translate-y-2')
          }
        >
          <div className="font-bold uppercase line-clamp-1">{name}</div>
          {/* {date} */}
        </p>
        {!(images && images.length > 0) && (
          <div
            className="line-clamp-3 w-10/12 mx-auto"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </div>
      {expand && (
        <div
          onClick={close}
          className={
            'fixed top-0 left-0 z-10 h-screen w-screen  bg-gray-500 opacity-50 backdrop-blur-sm cursor-pointer'
          }
        />
      )}
      {expand && (
        <div className="fixed top-[50%] left-[50%] grid max-h-[80vh] w-10/12 max-w-[1366px] -translate-x-[50%] z-10 -translate-y-[50%] grid-cols-2 items-center justify-center overflow-auto rounded-lg bg-white p-5 shadow-lg">
          {images && images.length > 0 && (
            <div
              className={
                (images && images.length > 0 ? ' ' : ' ') +
                ' col-span-2 flex w-full flex-col items-center justify-center sm:flex-row lg:col-span-1 lg:flex-col'
              }
            >
              <div className="flex w-full items-center justify-center sm:w-3/4 lg:w-full ">
                <GatsbyImage
                  image={images[selectedImage].gatsbyImageData}
                  alt={images[selectedImage].url}
                  className={'object-contain'}
                />
              </div>

              <div className="m-2 flex w-full flex-row items-center justify-center gap-3 sm:w-1/4 sm:flex-col lg:w-full lg:flex-row ">
                {images &&
                  images.length > 1 &&
                  images.map((image, i) => (
                    <div key={i} onClick={() => setSelectedImage(i)}>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.url}
                        className="max-w-[75px] border-2 p-2 md:max-w-[100px] cursor-pointer aspect-square"
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
          <div
            className={
              (images && images.length > 0 ? 'lg:col-span-1' : 'leading-loose py-10') +
              ' col-span-2 mx-auto w-full max-w-[800px]  lg:w-10/12'
            }
          >
            <p className="font-bold text-xl leading-relaxed">{date}</p>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <i className="text-gray-400">- {name}</i>
          </div>
        </div>
      )}
    </>
  )
}
TestimonialCard.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  images: PropTypes.array,
  text: PropTypes.string,
}

const Testimonials = () => {
  //run graphql query
  const { reviews } = useStaticQuery(graphql`
    query TestimonialsQuery {
      reviews: allStrapiCustomerReview(sort: { fields: date, order: DESC }) {
        nodes {
          name
          state
          images {
            url
            gatsbyImageDataMock
          }
          city
          text
          date(formatString: "MMMM Do, YYYY")
        }
      }
    }
  `)
  reviews?.nodes?.forEach(review => {
    review?.images?.forEach(image => {
      processGatsbyImageDataMock(image)
    })
  })
  const pageSize = 24
  const [limit, setLimit] = React.useState(pageSize)
  const loadMore = React.useCallback(() => setLimit(limit + pageSize), [limit, setLimit])
  const nodeRef = useInfinteScroll(limit, loadMore)

  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo title="Testimonials | Gray and Sons Jewelers" description="Why customers love Gray & Sons? Read authentic testimonials from our satisfied clients. Explore their experiences with our luxury watches and jewelry" canonical={'/testimonials/'}  />

      <section className="mx-auto my-10 w-10/12 max-w-[1366px]">
        <h1 className="text-center text-4xl font-bold">TESTIMONIALS</h1>
        <div className="mx-auto mb-5 max-w-max text-yellow-400">
          <IconStar />
        </div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {reviews.nodes
            .filter((_, i) => i < limit)
            .map((review, i) => (
              <TestimonialCard
                key={i}
                name={review.name}
                date={review.date}
                images={review.images}
                text={review.text}
              />
            ))}
        </div>
        {limit < reviews.nodes.length && (
          <button
            ref={nodeRef}
            onClick={loadMore}
            className="mx-auto my-10 block w-1/2 rounded-lg bg-red-700 py-4 text-2xl font-bold text-white hover:bg-red-800"
          >
            LOAD MORE
          </button>
        )}
      </section>
    </Layout>
  )
}

export default Testimonials
